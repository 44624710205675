/**
 * FORMS
 *
 * Global form functionality.
 */

'use strict';

/*
 * jQuery
 */
var $ = require( 'jquery' ),
	isMobile = window.lid && window.lid.channel && window.lid.channel === 'mobile' || $('body.mobile').length;;

/*
 * Ready
 */
$(function() {
	/*-- VALIDATION --*/
	require( '../../vendor/validation/quote-mini' );

	require( '../../vendor/validation/myown' );

	/*-- MINI CTA --*/
	var $quoteForm = $('.lid-quote-form');

	$('#lid-main-cta .lid-main-cta__btn a, #lid-mini-cta .lid-mini-cta__btn a').on('click', function( e ) {
	    var gutter = 16;

	    $('html, body').animate({ scrollTop: ( isMobile ? $quoteForm.offset().top - gutter : 0 ) }, 800);

	    e.preventDefault();
	});

	$('#gender, #age').on('change', function( e ) {
		if($quoteForm.length > 1)
		{
			var form = $(this).closest('form').attr('id');


			var age = $('#'+form+' #age').val();
			var gender = $('#'+form+' #gender').val();

			if(age && gender)
				$('#'+form).submit();
		}
		else
		{
			var age = $('#age').val();
			var gender = $('#gender').val();

			if(age && gender)
				$('#qform-mini').submit();
		}

		e.preventDefault();
    });
});